import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import AutocompleteField from "elements/AutocompleteField";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Menu from "./components/Menu";
import AmountOff from "./components/AmountOff";
import BuyXGetY from "./components/BuyXGetY";

import { ApiRequest } from "GlobalFunctions";
import ic_logic from 'icons/ic_logic.svg';
import { log } from "console";
import { LoadingButton } from "@mui/lab";

export default function LogicEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    openEditor, 
    setOpenEditor, 
    coupon_id,
    loadList
  } = props;


  const [logicType, setLogicType] = useState<any>({});


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
  
  const [logicValues, setLogicValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  

  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
    if (coupon_id) {
      axios.get(ProjectConfig.api_url + "/coupons/details", {
          params: {
            coupon_id: coupon_id,
          },
        })
        .then((response:any) => {
          const data = response.data;
          console.log("data", data);
          setLogicValues(data);
          setLogicType({
            coupon_type_id: data.coupon_type_id,
            coupon_type: data.coupon_type
          })
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setLogicValues({});
      setLogicType({})
    }
    
  } , [openEditor]);


  const [loading , setLoading] = useState(false);

  /*=======================================
  VALIDATION FUNCTION
  =======================================*/
  const validateFields = () => {
    let tempErrors: any = {};

    if (!logicValues.coupon_name) tempErrors.coupon_name = "Name is required";
    if (!logicValues.coupon_code) tempErrors.coupon_code = "Coupon code is required";

    if (logicType.coupon_type_id === 1) {
      /* Validations for AmountOff
      =======================================*/
      if (!logicValues.conditions || !logicValues.conditions.amount) tempErrors.amount = "Amount is required";
    } else if (logicType.coupon_type_id === 2) {
      /* Validations for BuyXGetY
      =======================================*/
      if (!logicValues.conditions || !logicValues.conditions.customerGets) {
        tempErrors.customerGetsProducts = "Customer Gets products are required";
        tempErrors.customerGetsQuantity = "Customer Gets quantity is required";
      } else {

        if (!logicValues.conditions.customerGets.products || logicValues.conditions.customerGets.products.length === 0) {
          tempErrors.customerGetsProducts = "Products are required";
        }
        if (!logicValues.conditions.customerGets.quantity || logicValues.conditions.customerGets.quantity <= 0) {
          tempErrors.customerGetsQuantity = "Quantity must be greater than 0";
        }
      }
    }

    if (logicValues.conditions && logicValues.conditions.applyToSpecificProducts) {
      if (!logicValues.conditions.products || logicValues.conditions.products.length === 0) {
        tempErrors.products = "Products are required";
      }
    }

    if (
      logicValues.conditions &&
      logicValues.conditions.redemptionLimits &&
      logicValues.conditions.redemptionLimits.limitByDate
    ) {
      if (!logicValues.conditions.redemptionLimits.startDate) {
        tempErrors.startDate = "Start date is required";
      }
      if (
        logicValues.conditions.redemptionLimits.enableEndDate &&
        !logicValues.conditions.redemptionLimits.endDate
      ) {
        tempErrors.endDate = "End date is required";
      }
    }
    console.log("tempErrors", tempErrors);
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {  
    if (!validateFields()) {
      enqueueSnackbar('Please fix the errors before submitting.', { variant: 'error' });
      return;
    }
    setLoading(true);
    await ApiRequest({
      method: 'POST',
      url: '/coupons/addedit',
      body: {
        ...logicValues,
        ...logicType
      },
      setResponse: (response: any) => {
        console.log("response", response);
        if(response.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
          
        }else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      }
    });
  };


  const getsubTitle = () => {
    if(Object.keys(logicType).length > 0){
      if(logicType.coupon_type_id === 1){
        return <h3 className="dialog_subtitle">Amount Off Products or Order</h3>
      }
      if(logicType.coupon_type_id === 2){
        return <h3 className="dialog_subtitle">Buy X Get Y</h3>
      }
    }

  }



  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => {
            setOpenEditor(false);
            setErrors({});
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{ sx: { borderRadius: "1.25rem", minWidth:"750px" } }}
        >

            
          <div className="DialogContainer">
            <div className="ModuleWrapper">
              <div className="boxContainerHeader">
                <div className="dialog_container_title">
                    <h2 className="dialog_title"><img src={ic_logic}/>
                      {Object.keys(logicType).length === 0 ? "Select Coupon Type" : coupon_id ? "Edit Coupon" : "Add Coupon"}
                    </h2>
                    
                    {getsubTitle()}
                </div>
              </div>
              {Object.keys(logicType).length === 0 ? (
                <div className="dialogFormContainer">
                  <Menu setLogicType={setLogicType} />
                </div>
              ) : (
              <div className="dialogFormContainer">
                {Object.keys(logicType).length > 0 && logicType.coupon_type_id === 1 &&
                  <AmountOff setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} errors={errors} setErrors={setErrors} />
                }
                
                {Object.keys(logicType).length > 0 && logicType.coupon_type_id === 2 &&
                  <BuyXGetY setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} errors={errors} setErrors={setErrors} />
                }
              </div>
              )}
              


              
              </div>
          </div>

          <div className="dialogButtons">

            {Object.keys(logicType).length > 0 &&
              <LoadingButton
                  onClick={handleSubmit}
                  variant="contained"
                  className="button_1"
                  loading={loading}
              >
                {coupon_id ? "Save Changes" : "Add Coupon"}
              </LoadingButton>
            }

            <Button
                onClick={() => {
                  setOpenEditor(false);
                  setLogicType({});
                  setLogicValues({});
                  setErrors({});
                }}
                variant="contained"
                color="secondary"
                className="button_secondary"
            >
              Cancel
            </Button>
            
            

            
          </div>

        </Dialog>
      </>
  );
}
