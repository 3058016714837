import {
    Button,
    Dialog,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
  } from "@mui/material";
  import { useState, useEffect } from "react";
  import ic_logic from "icons/ic_logic.svg";
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
  
  export default function RatesEditor(props: any) {
    const { openEditor, setOpenEditor, rate, onSave } = props;

    const defaultFormValues = {
        rate_name: "",
        price: "",
        min: "",
        max: "",
        basedOn: "",
        conditional: false,
        shipping_id: "",  // Add shipping_id
    };

    const [formValues, setFormValues] = useState(defaultFormValues);

    useEffect(() => {
        if (rate) {
            setFormValues({
                ...defaultFormValues,
                ...rate,
                conditional: rate.conditional ? true : false,
                shipping_id: rate.shipping_id || "",  // Ensure shipping_id is set
            });
        } else {
            setFormValues(defaultFormValues);
        }
    }, [rate, openEditor]);

    const handleSave = () => {
        onSave(formValues);  // shipping_id is included in formValues
    };
  
    return (
      <Dialog
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: "20px", minWidth: "750px" } }}
      >
        <div className="DialogContainer">
          <div className="dialog_container_title">
            <h2 className="dialog_title">
              <img src={ic_logic} alt="Logic Icon" />
              <span>Rates Editor</span>
            </h2>
          </div>
  
          <div className="dialogFormContainer">

                <TextField
                    label="Rate Name"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: "20px" }}
                    value={formValues.rate_name}
                    onChange={(e) =>
                      setFormValues({ ...formValues, rate_name: e.target.value })
                    }
                />
                <TextField
                    label="Price"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: "20px" }}
                    value={formValues.price}
                    onChange={(e) =>
                      setFormValues({ ...formValues, price: e.target.value })
                    }
                />

                <Button
                    variant="text"
                    onClick={() => {
                      const newConditional = !formValues.conditional;
                      setFormValues({
                        ...formValues,
                        conditional: newConditional,
                        basedOn: newConditional ? "weight" : "",
                        min: "",
                        max: "",
                      });
                    }}
                >
                    {formValues.conditional ? "Remove Conditional Pricing" : "Add Conditional Pricing"}
                </Button>
                {formValues.conditional && (
                  <div className="input_group">
                  
                    <RadioGroup
                      value={formValues.basedOn}
                      onChange={(e) =>
                        setFormValues({ ...formValues, basedOn: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="weight"
                        control={<Radio />}
                        label="Based on item weight"
                      />
                      <FormControlLabel
                        value="price"
                        control={<Radio />}
                        label="Based on order total"
                      />
                    </RadioGroup>

                    <div className="input_row">
                      <TextField
                        label={
                          formValues.basedOn === "weight" ? "Minimum Weight" : "Minimum Price"
                        }
                        variant="outlined"
                        fullWidth
                        type="number"
                        sx={{ marginBottom: "20px" }}
                        value={formValues.min}
                        onChange={(e) => setFormValues({ ...formValues, min: e.target.value })}
                        InputProps={{
                          startAdornment: formValues.basedOn === "price" ? <AttachMoneyIcon /> : null,
                          endAdornment: formValues.basedOn === "weight" ? "Lbs" : null
                        }}
                      />
                      <TextField
                        label={
                          formValues.basedOn === "weight" ? "Maximum Weight" : "Maximum Price"
                        }
                        variant="outlined"
                        fullWidth
                        type="number"
                        sx={{ marginBottom: "20px" }}
                        value={formValues.max}
                        onChange={(e) => setFormValues({ ...formValues, max: e.target.value })}
                        InputProps={{
                          startAdornment: formValues.basedOn === "price" ? <AttachMoneyIcon /> : null,
                          endAdornment: formValues.basedOn === "weight" ? "Lbs" : null
                        }}
                      />
                    </div>
                  </div>
                )}
                

          </div>
  
          <div className="dialogButtons">
            <Button
              onClick={handleSave}
              variant="contained"
              className="button_1"
            >
              Save
            </Button>
  
            <Button
              onClick={() => setOpenEditor(false)}
              variant="contained"
              color="secondary"
              className="button_secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
