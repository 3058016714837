import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    FormHelperText,
    Autocomplete,
    Chip,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiRequest } from "GlobalFunctions";
import ISwitch from "elements/ISwitch";
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import { set } from "lodash";

const defaultValues = {
    coupon_id: "",
    coupon_name: "",
    coupon_code: "",
    conditions: {
        type: "percentage",
        amount: null,
        applyToSpecificProducts: false,
        products: [],
        minimumPurchaseRequirements: {
            type: "none",
            value: 0
        },
        maximumDiscountUses: {
            isLimited: false,
            limit: 1
        },
        redemptionLimits: {
            limitByDate: false,
            startDate: "",
            enableEndDate: false,
            endDate: "",
            makeRecurring: false,
            recurringType: null
        }
    }
};


export default function AmountOff(props: any) {

    const {
        setLogicType,
        logicValues,
        setLogicvalues,
        errors,
        setErrors
    } = props;

    // Initialize logicValues with defaultValues if not already set
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized && !logicValues.coupon_id) {
            setLogicvalues(defaultValues);
            setInitialized(true);
        }
    }, [initialized, logicValues, setLogicvalues]);

    console.log("logicValues", logicValues);

    const generateCouponCode = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let coupon_code = "";
        for (let i = 0; i < 8; i++) {
            coupon_code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setLogicvalues({ ...logicValues, coupon_code });
    }

    return (
        <>
            {logicValues.conditions && (
                <>
            <div className="form_container">
                <div className="form_section">

                    <div className="form_input">
                        <h2 className="h2_section_title">Name</h2>
                        <TextField
                            fullWidth
                            size="small"
                            value={logicValues.coupon_name}
                            onChange={(e) => setLogicvalues({ ...logicValues, coupon_name: e.target.value })}
                            error={Boolean(errors.coupon_name)}
                            helperText={errors.coupon_name}
                        />
                    </div>

                    <div className="form_input">

                        <div className="input_title_container">
                            <h2 className="h2_section_title">Coupon Code</h2>
                                
                            <div className="coupon_code_button">
                                <Button
                                    variant="text"
                                    color="primary"
                                    sx={{ padding: "0px", '&:hover': { backgroundColor: 'transparent' } }}
                                    disableRipple
                                    disableTouchRipple
                                    onClick={() => {
                                        generateCouponCode();
                                    }}
                                >
                                    Generate Random Code
                                </Button>
                            </div>
                        </div>
                        <TextField
                            fullWidth
                            size="small"
                            value={logicValues.coupon_code}
                            onChange={(e) => setLogicvalues({ ...logicValues, coupon_code: e.target.value })}
                            error={Boolean(errors.coupon_code)}
                            helperText={errors.coupon_code}
                        />
                        <span className="tip">Customers must enter this code at checkout to receive the discount</span>
                    </div>
                </div>

                <Divider sx={{ borderColor: "#ECEBFF" }} />

                <div className="form_section">
                    <h2 className="h2_section_title">Type</h2>
                    <RadioGroup
                        row
                        aria-label="coupon_type"
                        name="coupon_type"
                        color="primary"
                        className="radio_group"
                        value={logicValues.conditions.type}
                        onChange={(e) =>
                            setLogicvalues({
                                ...logicValues,
                                conditions: { ...logicValues.conditions, type: e.target.value },
                            })
                        }
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <FormControlLabel style={{color: "#424242"}} value="percentage" control={<Radio />} label="Percentage Discount" />
                        <FormControlLabel style={{color: "#424242"}} value="fixed_amount" control={<Radio />} label="Fixed Amount Discount" />
                    </RadioGroup>

                    <TextField
                        fullWidth
                        label={logicValues.conditions.type === "percentage" ? "Percentage" : "Amount"}
                        type="number"
                        variant="outlined"
                        sx={{ width: "250px" }}
                        value={logicValues.conditions.amount}
                        onChange={(e) =>
                            setLogicvalues({
                                ...logicValues,
                                conditions: { ...logicValues.conditions, amount: e.target.value },
                            })
                        }
                        InputProps={{
                            startAdornment: logicValues.conditions.type !== "percentage" ? <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment> : null,
                            endAdornment: logicValues.conditions.type === "percentage" ? <InputAdornment position="end"><PercentIcon /></InputAdornment> : null
                        }}
                        error={Boolean(errors.amount)}
                        helperText={errors.amount}
                    />
                </div>

                <Divider sx={{ borderColor: "#ECEBFF" }} />

                <div className="form_section">
                    <FormControlLabel
                        control={
                            <ISwitch
                                checked={logicValues.conditions.applyToSpecificProducts}
                                onChange={(e: any) =>
                                    setLogicvalues({
                                        ...logicValues,
                                        conditions: {
                                            ...logicValues.conditions,
                                            applyToSpecificProducts: e.target.checked,
                                        },
                                    })
                                }
                            />
                        }
                        label="Apply to Specific Products"
                    />

                    {logicValues.conditions.applyToSpecificProducts && (
                        <div className="form_input">
                            <h2 className="h2_section_title">Products Coupon Applies To</h2>
                            <Autocomplete
                                multiple
                                freeSolo
                                options={[]}
                                value={logicValues.conditions.products}
                                onChange={(event, newValue) => {
                                    setLogicvalues({
                                        ...logicValues,
                                        conditions: {
                                            ...logicValues.conditions,
                                            products: newValue,
                                        },
                                    });
                                }}
                                renderTags={(value: string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter product SKUs"
                                        error={Boolean(errors.products)}
                                        helperText={errors.products}
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>

                <Divider sx={{ borderColor: "#ECEBFF" }} />
                
                <div className="form_section">
                    <h2 className="h2_section_title">Minimum Purchase Requirements</h2>
                    <RadioGroup
                        row
                        aria-label="minimum_purchase_requirements"
                        name="minimum_purchase_requirements"
                        value={logicValues.conditions.minimumPurchaseRequirements.type}
                        style={{ display: "flex", flexDirection: "column" }}
                        onChange={(e) =>
                            setLogicvalues({
                                ...logicValues,
                                conditions: {
                                    ...logicValues.conditions,
                                    minimumPurchaseRequirements: {
                                        ...logicValues.conditions.minimumPurchaseRequirements,
                                        type: e.target.value,
                                    },
                                },
                            })
                        }
                    >
                        <FormControlLabel value="none" control={<Radio />} label="No Minimum Requirements" />
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <FormControlLabel value="quantity" control={<Radio />} label="Require minimum number of products in cart" />
                            {logicValues.conditions.minimumPurchaseRequirements.type === "quantity" && (
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="number"
                                    sx={{ width: "150px" }}
                                    value={logicValues.conditions.minimumPurchaseRequirements.value}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment>
                                    }}
                                    onChange={(e) =>
                                        setLogicvalues({
                                            ...logicValues,
                                            conditions: {
                                                ...logicValues.conditions,
                                                minimumPurchaseRequirements: {
                                                    ...logicValues.conditions.minimumPurchaseRequirements,
                                                    value: e.target.value,
                                                },
                                            },
                                        })
                                    }
                                />
                            )}
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <FormControlLabel value="cartSubtotal" control={<Radio />} label="Require minimum cart subtotal" />
                            {logicValues.conditions.minimumPurchaseRequirements.type === "cartSubtotal" && (
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="number"
                                    sx={{ width: "150px" }}
                                    value={logicValues.conditions.minimumPurchaseRequirements.value}
                                    onChange={(e) =>
                                        setLogicvalues({
                                            ...logicValues,
                                            conditions: {
                                                ...logicValues.conditions,
                                                minimumPurchaseRequirements: {
                                                    ...logicValues.conditions.minimumPurchaseRequirements,
                                                    value: e.target.value,
                                                },
                                            },
                                        })
                                    }
                                />
                            )}
                        </div>
                    </RadioGroup>
                </div>

                <Divider sx={{ borderColor: "#ECEBFF" }} />

                <div className="form_section">
                    <h2 className="h2_section_title">Maximum Discount Uses</h2>
                    <FormControlLabel
                        control={
                            <ISwitch
                                checked={logicValues.conditions.maximumDiscountUses.isLimited}
                                onChange={(e: any) =>
                                    setLogicvalues({
                                        ...logicValues,
                                        conditions: {
                                            ...logicValues.conditions,
                                            maximumDiscountUses: {
                                                ...logicValues.conditions.maximumDiscountUses,
                                                isLimited: e.target.checked,
                                            },
                                        },
                                    })
                                }
                            />
                        }
                        label="Limit The Total Number Of Times This Coupon Can Be Redeemed"
                    />
                    
                    {logicValues.conditions.maximumDiscountUses.isLimited && (
                        <div className="switch_indent">
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                value={logicValues.conditions.maximumDiscountUses.limit}
                                sx={{ width: "250px" }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Time</InputAdornment>
                                }}
                                onChange={(e) =>
                                    setLogicvalues({
                                        ...logicValues,
                                        conditions: {
                                            ...logicValues.conditions,
                                            maximumDiscountUses: {
                                                ...logicValues.conditions.maximumDiscountUses,
                                                limit: e.target.value,
                                            },
                                        }
                                    })
                                }
                            />
                        </div>
                    )}
                </div>

                <Divider sx={{ borderColor: "#ECEBFF" }} />

                <div className="form_section">
                    <h2 className="h2_section_title">Redemption Limits</h2>
                    <FormControlLabel
                        control={
                            <ISwitch
                                checked={logicValues.conditions.redemptionLimits.limitByDate}
                                onChange={(e:any) =>
                                    setLogicvalues({
                                        ...logicValues,
                                        conditions: {
                                            ...logicValues.conditions,
                                            redemptionLimits: {
                                                ...logicValues.conditions.redemptionLimits,
                                                limitByDate: e.target.checked,
                                            },
                                        },
                                    })
                                }
                            />
                        }
                        label="Limit the date range when customers can redeem this coupon"
                    />

                    
                        {logicValues.conditions.redemptionLimits.limitByDate && (
                            <div className="switch_indent">
                                <TextField
                                    fullWidth
                                    label="Start Date"
                                    size="small"
                                    type="date"
                                    value={logicValues.conditions.redemptionLimits.startDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) =>
                                        setLogicvalues({
                                            ...logicValues,
                                            conditions: {
                                                ...logicValues.conditions,
                                                redemptionLimits: {
                                                    ...logicValues.conditions.redemptionLimits,
                                                    startDate: e.target.value,
                                                },
                                            }
                                        })
                                    }
                                    error={Boolean(errors.startDate)}
                                    helperText={errors.startDate}
                                />
                                

                                <FormControlLabel
                                    control={
                                        <ISwitch
                                            checked={logicValues.conditions.redemptionLimits.enableEndDate}
                                            onChange={(e:any) =>
                                                setLogicvalues({
                                                    ...logicValues,
                                                    conditions: {
                                                        ...logicValues.conditions,
                                                        redemptionLimits: {
                                                            ...logicValues.conditions.redemptionLimits,
                                                            enableEndDate: e.target.checked,
                                                        },
                                                    },
                                                })
                                            }
                                        />
                                    }
                                    label="Enable End Date"
                                />

                                {logicValues.conditions.redemptionLimits.enableEndDate && (
                                    <TextField
                                        fullWidth
                                        label="End Date"
                                        size="small"
                                        type="date"
                                        value={logicValues.conditions.redemptionLimits.endDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e:any) =>
                                            setLogicvalues({
                                                ...logicValues,
                                                conditions: {
                                                    ...logicValues.conditions,
                                                    redemptionLimits: {
                                                        ...logicValues.conditions.redemptionLimits,
                                                        endDate: e.target.value,
                                                    },
                                                },
                                            })
                                        }
                                        error={Boolean(errors.endDate)}
                                        helperText={errors.endDate}
                                    />
                                )}
                                {/* <FormControlLabel
                                    control={
                                        <ISwitch
                                            checked={logicValues.conditions.redemptionLimits.makeRecurring}
                                            onChange={(e: any) =>
                                                setLogicvalues({
                                                    ...logicValues,
                                                    conditions: {
                                                        ...logicValues.conditions,
                                                        redemptionLimits: {
                                                            ...logicValues.conditions.redemptionLimits,
                                                            makeRecurring: e.target.checked,
                                                        },
                                                    },
                                                })
                                            }
                                        />
                                    }
                                    label="Make Recurring"
                                />
                                {logicValues.conditions.redemptionLimits.makeRecurring && (
                                    <Select
                                        fullWidth
                                        label="Recurring Type"
                                        value={logicValues.conditions.redemptionLimits.recurringType || ''}
                                        onChange={(e) =>
                                            setLogicvalues({
                                                ...logicValues,
                                                conditions: {
                                                    ...logicValues.conditions,
                                                    redemptionLimits: {
                                                        ...logicValues.conditions.redemptionLimits,
                                                        recurringType: e.target.value,
                                                    },
                                                },
                                            })
                                        }
                                    >
                                        <MenuItem value="daily">Daily</MenuItem>
                                        <MenuItem value="weekly">Weekly</MenuItem>
                                        <MenuItem value="monthly">Monthly</MenuItem>
                                    </Select>
                                )} */}
                            </div>
                        )}
                    
                </div>

            </div>
                </>
            )}
        </>
    );
}