import {
    Button,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiRequest } from "GlobalFunctions";
import ISwitch from "elements/ISwitch";
import CloseIcon from '@mui/icons-material/Close';

const defaultConditions = () => ({
    rules: [
        {
            rule: "",
            custom_value: "",
            condition: "",
            value: "",
        }
    ],
    addToAll: true,
    shippingMethods: [] as any[],
    price: "",
});

const defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: [defaultConditions()],
}

export default function ShippingLogic(props: any) {

    const [shippingMethods, setShippingMethods] = useState<any[]>([]);
    const getShippingMethods = async () => {
        await ApiRequest({
            url: "/shipping/rates/list",
            method: "get",
            setResponse: (response: any) => {
                setShippingMethods(response);
            },
        });
    }

    useEffect(() => {
        getShippingMethods();
    }, []);

    const {
        setLogicType,
        logicValues,
        setLogicvalues
    } = props;

    useEffect(() => {
        console.log("logicValues----", logicValues);
        if (!logicValues.logic_id) {
            console.log("newww");
            setLogicvalues(defaultValues);
        }
    }, []);

    useEffect(() => {
        if (!logicValues.conditions) {
            setLogicvalues({
                ...logicValues,
                conditions: [defaultConditions()],
            });
        }
    }, [logicValues]);

    return (
        <>
            <InputLabel className="external_label">Name</InputLabel>
            <TextField
                fullWidth
                label="Enter Name To Identify"
                size="small"
                value={logicValues.logic_name}
                onChange={(e) => setLogicvalues({ ...logicValues, logic_name: e.target.value })}
            />

            <div className="input_group">
                {Array.isArray(logicValues.conditions) && logicValues.conditions.map((condition: any, index: number) => (
                    <div key={index} className="input_group">
                        <div className="dialog_section_title">
                            <div>
                                <h2 className="h2_section_title">Conditions</h2>
                                <span className="section_subtitle">Add special conditions here by clicking 'Add Rule'</span>
                            </div>
                            <IconButton
                                aria-label="delete"
                                disabled={logicValues.conditions.length === 1}
                                onClick={() => {
                                    let conditions = [...logicValues.conditions];
                                    conditions.splice(index, 1);
                                    setLogicvalues({ ...logicValues, conditions: conditions });
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>

                        {condition.rules.map((rule: any, ruleIndex: number) => {
                            
                            let type_value = "text";

                            if (rule.rule === "cart_total" || rule.rule === "weight") {
                                type_value = "number";
                            }

                            if(rule.condition === ">=" || rule.condition === "<=" || rule.condition === ">" || rule.condition === "<") {
                                type_value = "number";
                            }

                            return(
                            <div key={ruleIndex} className="input_group">
                                <div className="input_row">
                                {rule.rule !== "custom" ? (
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Rule</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="rule"
                                            value={rule.rule}
                                            label="Rule"
                                            name="rule"
                                            onChange={(e) => {
                                                let conditions = [...logicValues.conditions];
                                                conditions[index].rules[ruleIndex].rule = e.target.value;
                                                conditions[index].rules[ruleIndex].custom_value = "";
                                                conditions[index].rules[ruleIndex].value = "";
                                                setLogicvalues({ ...logicValues, conditions: conditions });
                                                
                                            }}
                                        >
                                            <MenuItem value={"cart_total"}>Cart Total</MenuItem>
                                            <MenuItem value={"weight"}>Weight</MenuItem>
                                            <MenuItem value={"category"}>Category</MenuItem>
                                            <MenuItem value={"custom"}>Custom</MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <TextField
                                        fullWidth
                                        label="Custom"
                                        value={rule.custom_value}
                                        onChange={(e) => {
                                            let conditions = [...logicValues.conditions];
                                            conditions[index].rules[ruleIndex].custom_value = e.target.value;
                                            setLogicvalues({ ...logicValues, conditions: conditions });
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => {
                                                        let conditions = [...logicValues.conditions];
                                                        conditions[index].rules[ruleIndex].rule = ""; // Cambia aquí para volver a mostrar el select
                                                        setLogicvalues({ ...logicValues, conditions: conditions });
                                                    }}>
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}


                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="condition"
                                            value={rule.condition}
                                            label="Condition"
                                            name="rule_condition"
                                            onChange={(e) => {
                                                let conditions = [...logicValues.conditions];
                                                conditions[index].rules[ruleIndex].condition = e.target.value;
                                                setLogicvalues({ ...logicValues, conditions: conditions });
                                            }}
                                        >
                                            <MenuItem value={"="}>Equals</MenuItem>
                                            <MenuItem value={"!="}>Does Not Equal</MenuItem>
                                            <MenuItem value={"=="}>Exact Match</MenuItem>
                                            {rule.rule !== "category" && 
                                            (<MenuItem value={">"}>Greater Than</MenuItem>)}
                                            {rule.rule !== "category" && (
                                            <MenuItem value={">="}>Greater Than or Equal To</MenuItem> )}
                                            {rule.rule !== "category" && (
                                            <MenuItem value={"<"}>Less Than</MenuItem> )}
                                            {rule.rule !== "category" && (
                                            <MenuItem value={"<="}>Less Than or Equal To</MenuItem> )}
                                            <MenuItem value={"boolean"}>Boolean</MenuItem>

                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        label="Value"
                                        value={rule.value}
                                        onChange={(e) => {
                                            let conditions = [...logicValues.conditions];
                                            conditions[index].rules[ruleIndex].value = e.target.value;
                                            setLogicvalues({ ...logicValues, conditions: conditions });
                                        }}
                                        type={type_value}
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        disabled={condition.rules.length === 1}
                                        onClick={() => {
                                            let conditions = [...logicValues.conditions];
                                            conditions[index].rules.splice(ruleIndex, 1);
                                            setLogicvalues({ ...logicValues, conditions: conditions });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )})}



                        <div className="form_button_wrapper">
                            <Button
                                variant="outlined"
                                color="info"
                                className="btnList"
                                onClick={() => {
                                    let conditions = [...logicValues.conditions];
                                    conditions[index].rules.push({
                                        rule: "",
                                        condition: "",
                                        value: "",
                                    });
                                    setLogicvalues({ ...logicValues, conditions: conditions });
                                }}
                            >
                                Add Rule
                            </Button>
                        </div>

                        <RadioGroup
                            row
                            aria-label="addToAll"
                            name="addToAll"
                            color="primary"
                            className="radio_group"
                            value={condition.addToAll}
                            onChange={(e) => {
                                let conditions = [...logicValues.conditions];
                                conditions[index].addToAll = e.target.value === "true" ? true : false;
                                setLogicvalues({ ...logicValues, conditions: conditions });
                            }}
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <FormControlLabel style={{color: "#424242"}} value="true" control={<Radio />} label="Apply to all shipping classes" />
                            <FormControlLabel style={{color: "#424242"}} value="false" control={<Radio />} label="Apply to specific shipping classes" />
                        </RadioGroup>

                        {!logicValues.conditions[index].addToAll ? (
                            <div className="logic_shipping_methods">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Shipping Method</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {shippingMethods.map((method: any, methodIndex: number) => (
                                        <TableRow key={methodIndex}>
                                            <TableCell>
                                                <ISwitch
                                                    label={method.rate_name}
                                                    className = "input_switch"
                                                    onChange={(e: any) => {
                                                        let conditions = [...logicValues.conditions];
                                                        if (e.target.checked) {
                                                            conditions[index].shippingMethods.push({ shipping_id: method.shipping_id, price: "" });
                                                        } else {
                                                            conditions[index].shippingMethods = conditions[index].shippingMethods.filter((item: any) => item.shipping_id !== method.shipping_id);
                                                        }
                                                        setLogicvalues({ ...logicValues, conditions: conditions });
                                                    }}
                                                    checked={condition.shippingMethods.some((item: any) => item.shipping_id === method.shipping_id)}
                                                    name={method.rate_name}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <div className="logic_shipping_price_container">
                                                    <span className="logic_shipping_price">PRICE</span>
                                                    {condition.shippingMethods.some((item: any) => item.shipping_id === method.shipping_id)? (
                                                        <TextField
                                                            fullWidth
                                                            label="Price"
                                                            size = "small"
                                                            value={condition.shippingMethods.find((item: any) => item.shipping_id === method.shipping_id).price}
                                                            onChange={(e) => {
                                                                let conditions = [...logicValues.conditions];
                                                                conditions[index].shippingMethods.find((item: any) => item.shipping_id === method.shipping_id).price = e.target.value;
                                                                setLogicvalues({ ...logicValues, conditions: conditions });
                                                            }}
                                                        />
                                                    ) : (
                                                        <TextField
                                                            disabled
                                                            sx={{opacity: 0.5}}
                                                            fullWidth
                                                            label="Price"
                                                            size = "small"
                                                            value=""
                                                        />
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </div>
                        ) : (
                            
                            <TextField
                                fullWidth
                                label="Price"
                                variant="outlined"
                                value={condition.price}
                                style={{maxWidth: "200px"}}
                                onChange={(e) => {
                                    let conditions = [...logicValues.conditions];
                                    conditions[index].price = e.target.value;
                                    setLogicvalues({ ...logicValues, conditions: conditions });
                                }}
                            />
                        )}
                    </div>
                ))}
                <div className="form_button_wrapper">
                    <Button
                        variant="outlined"
                        color="info"
                        className="btnList"
                        onClick={() => {
                            let conditions = [...logicValues.conditions];
                            conditions.push(defaultConditions());
                            setLogicvalues({ ...logicValues, conditions: conditions });
                        }}
                    >
                        Add Condition
                    </Button>
                </div>

            </div>
        </>
    )
}
