import { Button, Dialog, TextField, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ApiRequest } from "GlobalFunctions";
import ic_logic from 'icons/ic_logic.svg';
import Flag from 'react-country-flag';
import ZonesEditor from "./components/ZonesEditor";
import RatesList from "./components/Rates/RatesList";

export default function ShippingZonesEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, shipping_zone_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [openZoneEditor, setOpenZoneEditor] = useState(false);

  const defaultFormValues = {
    shipping_zone_id: shipping_zone_id,
    shipping_name: "",
    zones: [],
    rates: [],
  };

  

  const [formValues, setFormValues] = useState<any>(defaultFormValues);

  useEffect(() => {
    if (shipping_zone_id) {
      getDetails();
    } else {
      setFormValues(defaultFormValues);
    }
  }, [openEditor]);

  /*=========================================================
  GET DETAILS
  =========================================================*/
  const getDetails = async () => {
    setLoading(true);
    await ApiRequest({
      method: 'GET',
      url: '/shipping/zones/details',
      query: { shipping_zone_id: shipping_zone_id },
      setResponse: (response: any) => {
        setFormValues({
          ...response,
          zones: response.zones || [],
          rates: response.rates || [],  // rates include shipping_id from API
        });
      }
    });
    setLoading(false);
  };

  /*=========================================================
  HANDLE SUBMIT
  =========================================================*/
  const handleSubmit = async () => {
    setLoading(true);
    await ApiRequest({
      method: 'POST',
      url: '/shipping/zones/addedit',
      body: {
        ...formValues,
        zones: formValues.zones || [],
        rates: formValues.rates || [],  // rates include shipping_id to be submitted
      },
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }
    });
    setLoading(false);
  };

  /*=========================================================
  HANDLE CLOSE
  =========================================================*/
  const handleClose = () => {
    setOpenEditor(false);
    setFormValues(defaultFormValues);
  };

  return (
    <>
      <Dialog
        open={openEditor}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: "20px", minWidth: "750px" } }}
      >
        <div className="DialogContainer">
          <div className="dialog_container_title">
            <h2 className="dialog_title">
              <img src={ic_logic} alt="Logic Icon" />
              {shipping_zone_id ? "Edit Shipping Class" : "Add Shipping Class"}
            </h2>
          </div>

          <div className="dialogFormContainer">
            <div className="input_group">
              <TextField
                fullWidth
                label="Shipping Name"
                variant="outlined"
                value={formValues.shipping_name}
                onChange={(e) => setFormValues({ ...formValues, shipping_name: e.target.value })}
              />
              <p className="field_tip">Name will appear on your dashboard</p>
              
              <div className="shipping_zones_row">
                <h2 className="h2_section_title">Shipping Zones</h2>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setOpenZoneEditor(true)}
                >
                  Select Zones
                </Button>
              </div>

              <div className="shipping_zones_list">
                {Array.isArray(formValues.zones) && formValues.zones.length ? (
                  formValues.zones.map((zone: any, i: number) => (
                    <div key={i} className="shipping_zone_item">
                      <div className="zone_country">
                        <Flag countryCode={ zone.country_code } svg
                          style={{
                            width: 25,
                            height: 19,
                            borderRadius: 3,
                            objectFit: 'cover',
                        }}/>
                        {zone.country_name}
                        {zone.all_country && <Chip label="All Country" size="small" style={{ marginLeft: 8 }} />}
                      </div>
                      
                      {zone.regions && zone.regions.length > 0 && (
                        <ul className="zone_list">
                          {zone.regions.map((region: any, j: number) => (
                            <li key={j}>
                              {region.region_name || `Region ID: ${region.region_id}`}
                              {region.all_region && <Chip label="All Region" size="small" style={{ marginLeft: 8 }} />}
                              
                              {region.cities && region.cities.length > 0 && (
                                <ul>
                                  {region.cities.map((city: any, k: number) => (
                                    <li key={k}>{city.city_name}</li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="noZones">
                    <p>Add zones to create rates for places you want to ship to</p>
                  </div>
                )}
              </div>
            </div>



            <RatesList
              rates={formValues.rates || []}
              setRates={(updatedRates: any[]) =>
                setFormValues({ ...formValues, rates: updatedRates || [] })
              }
            />

          </div>

                



        </div>



        <div className="dialogButtons">
          <Button
            onClick={handleSubmit}
            variant="contained"
            className="button_1"
          >
            {shipping_zone_id ? "Edit Shipping Class" : "Add Shipping Class"}
          </Button>

          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            className="button_secondary"
          >
            Cancel
          </Button>
        </div>
      </Dialog>
      <ZonesEditor 
        openEditor={openZoneEditor} 
        setOpenEditor={setOpenZoneEditor} 
        zones={formValues.zones || []}
        setZones={(updatedZones: any[]) =>
          setFormValues({ ...formValues, zones: updatedZones || [] })
        }
      />
    </>
  );
}
