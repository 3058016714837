import {
  Button,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from "notistack";
import Flag from 'react-country-flag';
import { ApiRequest } from "GlobalFunctions";
import ic_logic from "icons/ic_logic.svg";
import AutocompleteField from "elements/AutocompleteField";

export default function ZonesEditor(props: any) {
  const { openEditor, setOpenEditor, zones = [], setZones } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [locations, setLocations] = useState<any[]>([]);
  const [expandedCountry, setExpandedCountry] = useState<number | null>(null);
  const [loadingCountryId, setLoadingCountryId] = useState<number | null>(null);
  const [expandedRegions, setExpandedRegions] = useState<Set<number>>(new Set());
  const [selectedCities, setSelectedCities] = useState<{ [region_id: string]: any[] }>({});
  const [regionChecks, setRegionChecks] = useState<{ [region_id: number]: boolean }>({});
  const [countryChecks, setCountryChecks] = useState<{ [country_id: number]: boolean }>({});

  const [tempZones, setTempZones] = useState<any[]>([...zones]);

  const getCountries = async () => {
    await ApiRequest({
      method: "GET",
      url: "/shipping/zones/getCountries",
      setResponse: (response: any) => {
        setLocations(response.groups);
      },
    });
  };

  useEffect(() => {
    if (openEditor) {
      setTempZones([...zones]); // Inicializar tempZones con zones
      initializeChecksAndSelections(zones); // Configurar los checks y selecciones
      getCountries(); // Cargar los países al abrir el editor
      setExpandedCountry(null); // Close all country accordions
      setExpandedRegions(new Set()); // Close all region accordions
    }
  }, [openEditor, zones]);

  const initializeChecksAndSelections = (zonesData:any) => {
    const countriesChecked: any = {};
    const regionsChecked: any = {};
    const citiesSelected: any = {};
    const countryIndeterminate: any = {};
    const regionIndeterminate: any = {};
  
    zonesData.forEach((zone:any) => {
      countriesChecked[zone.country_id] = zone.all_country || false;
  
      // Calcular indeterminate para el país si tiene regiones parcialmente seleccionadas
      const hasSelectedRegions = zone.regions.some((region: any) => region.all_region || (selectedCities[region.region_id]?.length > 0));
      countryIndeterminate[zone.country_id] = hasSelectedRegions && !zone.all_country;
  
      zone.regions.forEach((region: any) => {
        regionsChecked[region.region_id] = region.all_region || false;
        citiesSelected[region.region_id] = region.cities || [];
  
        // Calcular indeterminate para la región si tiene ciudades parcialmente seleccionadas
        const hasSelectedCities = citiesSelected[region.region_id].length > 0;
        regionIndeterminate[region.region_id] = hasSelectedCities && !region.all_region;
      });
    });
  
    setCountryChecks(countriesChecked);
    setRegionChecks(regionsChecked);
    setSelectedCities(citiesSelected);
    setCountryIndeterminate(countryIndeterminate);
    setRegionIndeterminate(regionIndeterminate);
  };
  

  const [countryIndeterminate, setCountryIndeterminate] = useState<{ [country_id: number]: boolean }>({});
const [regionIndeterminate, setRegionIndeterminate] = useState<{ [region_id: number]: boolean }>({});

  
  

  const getRegions = async (country_id: number) => {
    const countryAlreadyLoaded = locations
      .flatMap((group: any) => group.countries)
      .some(
        (country: any) =>
          country.country_id === country_id &&
          country.regions &&
          country.regions.length > 0
      );

    if (countryAlreadyLoaded) {
      setExpandedCountry(country_id);
      return;
    }

    setLoadingCountryId(country_id);
    await ApiRequest({
      method: "GET",
      url: "/shipping/zones/getRegions",
      query: { country_id },
      setResponse: (response: any) => {
        setLocations((prevLocations: any) =>
          prevLocations.map((group: any) => ({
            ...group,
            countries: group.countries.map((country: any) =>
              country.country_id === country_id
                ? { ...country, regions: response.regions || [] }
                : country
            ),
          }))
        );
        setLoadingCountryId(null);
        setExpandedCountry(country_id);
      },
    });
  };

  const handleCountryExpand = (country_id: number) => {
    const countryAlreadyLoaded = locations
      .flatMap((group: any) => group.countries)
      .some(
        (country: any) =>
          country.country_id === country_id &&
          country.regions &&
          country.regions.length > 0
      );
  
    if (countryAlreadyLoaded) {
      // Toggle expansion if regions are already loaded
      setExpandedCountry(expandedCountry === country_id ? null : country_id);
    } else {
      // Show loader and fetch regions
      setLoadingCountryId(country_id);
      getRegions(country_id);
    }
  };

  const handleRegionExpand = (region_id: number, isExpanded: boolean) => {
    setExpandedRegions((prev) => {
      const newSet = new Set(prev);
      if (isExpanded) {
        newSet.add(region_id);
      } else {
        newSet.delete(region_id);
      }
      return newSet;
    });
  };

  const handleCountryCheck = (e: any, country: any) => {
    const isChecked = e.target.checked;
  
    setCountryChecks((prev) => ({
      ...prev,
      [country.country_id]: isChecked,
    }));
  
    let updatedZones = [...tempZones];
  
    // Remueve el país de tempZones si ya existe
    updatedZones = updatedZones.filter(
      (z) => z.country_id !== country.country_id
    );
  
    // Si está seleccionado, añade el país con all_country: true
    if (isChecked) {
      updatedZones.push({
        country_id: country.country_id,
        country_name: country.country_name,
        country_code: country.country_code, // Añadir aquí
        all_country: true,
        regions: [],
      });
    }
  
    setTempZones(updatedZones);
  
    // Calcular indeterminate para el país en base a la selección de regiones
    const anyRegionSelected = locations
      .flatMap((group: any) => group.countries)
      .find((c: any) => c.country_id === country.country_id)
      .regions?.some((region: any) => regionChecks[region.region_id] || selectedCities[region.region_id]?.length > 0);
  
    setCountryIndeterminate((prev) => ({
      ...prev,
      [country.country_id]: anyRegionSelected && !isChecked,
    }));
  
    // Resetear el estado de las regiones y ciudades bajo este país
    setRegionChecks((prev) => {
      const updated = { ...prev };
      locations.forEach((group: any) => {
        group.countries.forEach((c: any) => {
          if (c.country_id === country.country_id && c.regions) {
            c.regions.forEach((region: any) => {
              delete updated[region.region_id];
              delete selectedCities[region.region_id];
            });
          }
        });
      });
      return updated;
    });
  
    setSelectedCities((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((region_id) => {
        const region = locations
          .flatMap((group: any) => group.countries)
          .flatMap((c: any) => c.regions || [])
          .find((r: any) => r.region_id === parseInt(region_id));
        if (region && region.country_id === country.country_id) {
          delete updated[region_id];
        }
      });
      return updated;
    });
  };
  

  const handleRegionCheck = (e: any, country: any, region: any) => {
    const isChecked = e.target.checked;
  
    setRegionChecks((prev) => ({
      ...prev,
      [region.region_id]: isChecked,
    }));
  
    let updatedZones = [...tempZones];
  
    // Encuentra o crea el país en tempZones
    let countryIndex = updatedZones.findIndex((z) => z.country_id === country.country_id);
    if (countryIndex === -1) {
      updatedZones.push({
        country_id: country.country_id,
        country_name: country.country_name,
        country_code: country.country_code,
        all_country: false,
        regions: [],
      });
      countryIndex = updatedZones.length - 1;
    }
    let countryObj = updatedZones[countryIndex];
  
    // Remueve la región existente si ya está en el país
    countryObj.regions = countryObj.regions.filter((r: any) => r.region_id !== region.region_id);
  
    // Si está seleccionada, añade la región con all_region: true
    if (isChecked) {
      countryObj.regions.push({
        region_id: region.region_id,
        region_name: region.region_name,
        all_region: true,
        cities: [],
      });
    }
  
    // Actualiza el país en tempZones
    updatedZones[countryIndex] = countryObj;
    setTempZones(updatedZones);
  
    // Calcular indeterminate para la región en base a la selección de ciudades
    const anyCitySelected = selectedCities[region.region_id]?.length > 0;
    setRegionIndeterminate((prev) => ({
      ...prev,
      [region.region_id]: anyCitySelected && !isChecked,
    }));
  
    // Eliminar ciudades seleccionadas si la región se deselecciona
    setSelectedCities((prev) => {
      const updated = { ...prev };
      if (!isChecked) delete updated[region.region_id];
      return updated;
    });
  };
  

  const handleCitySelection = (value: any, country: any, region: any) => {
    if (!value) return;
  
    setSelectedCities((prev) => {
      const regionCities = prev[region.region_id] || [];
      if (!regionCities.find((city: any) => city.city_id === value.value)) { // Cambia `value.value` a `value.city_id`
        return {
          ...prev,
          [region.region_id]: [
            ...regionCities,
            { city_id: value.value, city_name: value.label }, // Cambia a `city_id` y `city_name`
          ],
        };
      } else {
        return prev;
      }
    });
  
    let updatedZones = [...tempZones];
  
    let countryIndex = updatedZones.findIndex((z) => z.country_id === country.country_id);
    if (countryIndex === -1) {
      updatedZones.push({
        country_id: country.country_id,
        country_name: country.country_name,
        country_code: country.country_code,
        all_country: false,
        regions: [],
      });
      countryIndex = updatedZones.length - 1;
    }
    let countryObj = updatedZones[countryIndex];
  
    let regionIndex = countryObj.regions.findIndex((r: any) => r.region_id === region.region_id);
    if (regionIndex === -1) {
      countryObj.regions.push({
        region_id: region.region_id,
        region_name: region.region_name,
        all_region: false,
        cities: [],
      });
      regionIndex = countryObj.regions.length - 1;
    }
    let regionObj = countryObj.regions[regionIndex];
  
    if (!regionObj.cities.find((city: any) => city.city_id === value.value)) {
      regionObj.cities.push({
        city_id: value.value,
        city_name: value.label,
      });
    }
  
    countryObj.regions[regionIndex] = regionObj;
    updatedZones[countryIndex] = countryObj;
  
    setTempZones(updatedZones);
  };
  

  const handleCityRemove = (cityToRemove: any, region_id: number, country_id: number) => {
    setSelectedCities((prev) => {
      const updatedCities = prev[region_id].filter(
        (city: any) => city.city_id !== cityToRemove.city_id
      );
      return {
        ...prev,
        [region_id]: updatedCities,
      };
    });
  
    let updatedZones = [...tempZones];
  
    let countryIndex = updatedZones.findIndex((z) => z.country_id === country_id);
    if (countryIndex !== -1) {
      let countryObj = {
        ...updatedZones[countryIndex],
        country_code: updatedZones[countryIndex].country_code || '', // Añadir `country_code` si no existe
      };
  
      let regionIndex = countryObj.regions.findIndex((r: any) => r.region_id === region_id);
      if (regionIndex !== -1) {
        let regionObj = countryObj.regions[regionIndex];
  
        regionObj.cities = regionObj.cities.filter(
          (city: any) => city.city_id !== cityToRemove.city_id
        );
  
        if (regionObj.cities.length === 0) {
          countryObj.regions.splice(regionIndex, 1);
        } else {
          countryObj.regions[regionIndex] = regionObj;
        }
  
        if (countryObj.regions.length === 0 && !countryObj.all_country) {
          updatedZones.splice(countryIndex, 1);
        } else {
          updatedZones[countryIndex] = countryObj;
        }
      }
    }
  
    setTempZones(updatedZones);
  };
  

  useEffect(() => {
    initializeChecksAndSelections(tempZones);
  }, [tempZones]);
  
  

  return (
    <>
      <Dialog
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: "20px", minWidth: "450px" } }}
      >
        <div className="DialogContainer">
          <div className="dialog_container_title">
            <h2 className="dialog_title">
              <img src={ic_logic} alt="Logic Icon" />
              <span>Shipping Zones</span>
            </h2>
          </div>

          <div className="dialogFormContainer">
            <div className="locations_zones">
              {locations.map((group: any) => (
                <div key={group.group_location_id} className="location_group">
                  <h3>{group.group_label}</h3>
                  {group.countries.map((country: any) => (
                    <Accordion
                      key={country.country_id}
                      expanded={expandedCountry === country.country_id}
                      onChange={() => handleCountryExpand(country.country_id)}
                      disabled={loadingCountryId === country.country_id} // Disable while loading
                      sx={{ boxShadow: 'none' }}
                      className='menuAccordion' 
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-content-${country.country_id}`}
                        id={`panel-header-${country.country_id}`}
                        className='menuAccordionItem'
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={countryChecks[country.country_id] || false}
                              indeterminate={countryIndeterminate[country.country_id] || false}
                              onChange={(e) => handleCountryCheck(e, country)}
                            />
                          }
                          label={
                            <div className="country_label">
                              <Flag countryCode={ country.country_code } svg
                                style={{
                                  width: 25,
                                  height: 19,
                                  borderRadius: 3,
                                  objectFit: 'cover',
                              }}/>
                              {country.country_name}
                            </div>
                              }
                        />
                        
                      </AccordionSummary>
                      {loadingCountryId === country.country_id && (
                        <LinearProgress /> // Show loader below the accordion summary
                      )}
                      {country.regions && country.regions.length > 0 && (
                        <AccordionDetails className='menuAccordionDetails'>
                          {loadingCountryId === country.country_id ? (
                            <LinearProgress />
                          ) : country.regions && country.regions.length > 0 ? (
                            country.regions.map((region: any) => (
                              <Accordion
                                key={region.region_id}
                                expanded={expandedRegions.has(region.region_id)}
                                onChange={(event, isExpanded) =>
                                  handleRegionExpand(region.region_id, isExpanded)
                                }
                                sx={{ 
                                  boxShadow: 'none',
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel-content-${region.region_id}`}
                                  id={`panel-header-${region.region_id}`}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={regionChecks[region.region_id] || false}
                                        indeterminate={regionIndeterminate[region.region_id] || false}
  
                                        onChange={(e) => handleRegionCheck(e, country, region)}
                                      />
                                    }
                                    label={region.region_name}
                                  />
                                </AccordionSummary>
                                <AccordionDetails className='menuAccordionDetails'>
                                  {expandedRegions.has(region.region_id) && (
                                    <>
                                      <AutocompleteField
                                        label="City"
                                        endpoint="/shipping/zones/getCities"
                                        endpoint_params={{ region_id: region.region_id }}
                                        max_results={10}
                                        dynamic={true}
                                        value={null}
                                        onChange={(value: any) => {
                                          handleCitySelection(value, country, region);
                                        }}
                                        clearOnSelect={true}
                                      />
                                    
                                    {selectedCities[region.region_id] && selectedCities[region.region_id].length > 0 &&
                                      <div className="cities_selected">
                                        {selectedCities[region.region_id].map((city: any) => (
                                            <div key={city.city_id} className="selected_city_item">
                                              <span>{city.city_name}</span>
                                              <IconButton
                                                aria-label="delete"
                                                onClick={() => handleCityRemove(city, region.region_id, country.country_id)}
                                                size="small"
                                              >
                                                <DeleteIcon fontSize="small" />
                                              </IconButton>
                                            </div>
                                          ))}
                                      </div>
                                    }
  
                                    </>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            ))
                          ) : (
                            <LinearProgress />
                          )}
                        </AccordionDetails>
                      )}
                    </Accordion>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="dialogButtons">
          <Button
            onClick={() => {
              setZones(tempZones); // Commit tempZones to main zones state
              setOpenEditor(false);
            }}
            variant="contained"
            className="button_1"
          >
            Add Zones
          </Button>

          <Button
            onClick={() => setOpenEditor(false)}
            variant="contained"
            color="secondary"
            className="button_secondary"
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
}
