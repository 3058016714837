import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { ApiRequest } from 'GlobalFunctions';
import debounce from 'lodash/debounce';

export default function AutocompleteField(props: any) {
  const {
    endpoint = "",
    endpoint_params = {},
    label,
    value,
    onChange,
    fullwidth = false,
    dynamic = false,
    max_results = null,
    clearOnSelect = false,
  } = props;

  const [list, setList] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    getList(); // Load the initial list when the component mounts or endpoint_params change
  }, [JSON.stringify(endpoint_params)]);

  /*=========================================================
    GET LIST
  =========================================================*/
  const getList = async (limit: number | null = null, inputValueParam: string = "") => {
    const filters = dynamic && inputValueParam !== ''
      ? [{ key: 'city_name', value: inputValueParam, action: 'search', condition: '=' }]
      : undefined;

    await ApiRequest({
      url: endpoint,
      method: "get",
      headers: {
        ltkn: localStorage.getItem('ltkn'),
      },
      query: {
        full_list: limit === null,
        max_results: limit || undefined,
        ...endpoint_params,
        filters,
      },
      setResponse: (response: any) => {
        setList(response.list || []);
      },
    });
  };

  // Debounced function to fetch limited results based on max_results
  const debouncedGetList = debounce((inputValueParam: string) => {
    getList(max_results, inputValueParam);
  }, 500);

  /*=========================================================
    HANDLE CHANGE IN AUTOCOMPLETE INPUT
  =========================================================*/
  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue);

    if (dynamic) {
      if (newInputValue === '') {
        // Input cleared, cancel pending calls and fetch full list
        debouncedGetList.cancel();
        getList(max_results, '');
      } else {
        debouncedGetList(newInputValue);
      }
    }
  };

  /*=========================================================
    HANDLE SELECT
  =========================================================*/
  const handleSelect = (event: any, newValue: any) => {
    onChange(newValue);

    if (clearOnSelect) {
      setInputValue(''); // Clear the input
      debouncedGetList.cancel(); // Cancel any pending debounced calls
      getList(max_results, ''); // Fetch the full list immediately
    } else {
      setInputValue(newValue ? newValue.label : '');
    }
  };

  /*=========================================================
    RETURN
  =========================================================*/
  return (
    <Autocomplete
      value={null}
      inputValue={inputValue}
      onChange={handleSelect}
      onInputChange={handleInputChange}
      id="autocomplete"
      options={list}
      fullWidth={fullwidth}
      getOptionLabel={(option: any) => option.label}
      renderOption={(props, option) => (
        <li className="flex_select" {...props}>
          <div className="flex_option_1">{option.label}</div>
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    />
  );
}
