import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function CouponsMenu(props: any) {

    const {
        setLogicType
    } = props;

    return (
        <>


            <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                    logic_type: "amount_off",
                    coupon_type_id: 1
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Amount Off Products or Order</span>
                    <span className="login_button_description">Apply discounts to selected products</span>
                </div>
                </Button>

                <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                    logic_type: "buy_x_get_y",
                    coupon_type_id: 2
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Buy X Get Y</span>
                    <span className="login_button_description">Reward purchases with free products or discounts on additional items</span>
                </div>
                </Button>


               
            </>
        )
}