import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, Menu, MenuItem } from '@mui/material';
import RatesEditor from './RatesEditor';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function RatesList(props: any) {

    const { rates, setRates } = props;

    const [openEditor, setOpenEditor] = useState(false);
    const [selectedRateIndex, setSelectedRateIndex] = useState<number | null>(null);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    const handleEdit = (index: number) => {
        setSelectedRateIndex(index);
        setOpenEditor(true);
    };

    const handleDelete = (index: number) => {
        const updatedRates = [...rates];
        updatedRates.splice(index, 1);
        setRates(updatedRates);
    };

    const handleSave = (rate: any) => {
        const updatedRates = [...rates];
        if (selectedRateIndex !== null) {
            // Update existing rate
            updatedRates[selectedRateIndex] = rate;  // shipping_id is preserved
        } else {
            // Add new rate
            updatedRates.push(rate);
        }
        setRates(updatedRates);
        setOpenEditor(false);
        setSelectedRateIndex(null);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setMenuAnchor(event.currentTarget);
        setSelectedRateIndex(index);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };
    
    return (
        <div className="rates_list_container">
            <TableContainer sx={{ width: "100%" }}>
                <Table sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Rate Name</TableCell>
                            <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Price</TableCell>
                            <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {rates.map((rate: any, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{rate.rate_name}</TableCell>
                                <TableCell>{rate.price}</TableCell>
                                <TableCell width={100} align="right">
                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            
            <div className="rates_button_container">
                <Button
                    variant="outlined"
                    color="info"
                    className="btnList"
                    onClick={() => {
                        setSelectedRateIndex(null);
                        setOpenEditor(true);
                    }}
                >
                    Add Rate
                </Button>
            </div>

            <RatesEditor
                openEditor={openEditor}
                setOpenEditor={setOpenEditor}
                rate={selectedRateIndex !== null ? rates[selectedRateIndex] : null}
                onSave={handleSave}
            />

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => {
                    handleEdit(selectedRateIndex!);
                    handleMenuClose();
                }}>
                    <EditIcon fontSize="small" />
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    handleDelete(selectedRateIndex!);
                    handleMenuClose();
                }}>
                    <DeleteIcon fontSize="small" />
                    Delete
                </MenuItem>
            </Menu>
        </div>
    )
}

